import * as React from "react"
import { Link, graphql, Share, LinkLabel, ShareLink } from "gatsby"
import BlogLayout from "../components/blogLayout"
import Seo from "../components/seo"

const BlogPostTemplate = ({
  data: { previous, next, site, markdownRemark: post },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`

  return (
    <BlogLayout location={location} title={siteTitle}>
      <article
        className="blog-post w-full "
        itemScope
        itemType="http://schema.org/Article"
      >
        <section class="relative bg-primary">

          <section class="w-full md:w-4/5 2xl:w-3/5 mx-auto items-center">
            <header class="text-center text-5xl">
              <h1 itemProp="headline">{post.frontmatter.title}</h1>
              <p>{post.frontmatter.date}</p>
            </header>
          </section>
        </section>
        <div class="waves">

          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
          </svg>
        </div>
        <section class="w-full md:w-4/5 2xl:w-3/5 mx-auto items-center"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
      </article>
      <nav className="blog-post-nav w-full md:w-4/5 2xl:w-3/5 mx-auto items-center">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </BlogLayout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
            query BlogPostBySlug(
            $id: String!
            $previousPostId: String
            $nextPostId: String
            ) {
              site {
              siteMetadata {
              title
            }
    }
            markdownRemark(id: {eq: $id }) {
              id
      excerpt(pruneLength: 160)
            html
            frontmatter {
              title
        date(formatString: "MMMM DD, YYYY")
            description
      }
    }
            previous: markdownRemark(id: {eq: $previousPostId }) {
              fields {
              slug
            }
            frontmatter {
              title
            }
    }
            next: markdownRemark(id: {eq: $nextPostId }) {
              fields {
              slug
            }
            frontmatter {
              title
            }
    }
  }
            `
